<template>
    <v-container>
        <!-- <h2 class="mb-4 text-uppercase">Solicitudes con observacion</h2> -->
        <v-row align="center">
            <!-- FILTROS -->
    <v-col cols="12" sm="12" md="4">
        <v-autocomplete
            v-model="filters.id_ctl_cm_tipo_solicitud"
            label="Tipo"
            :items="ctlTipo"
            item-value="id"
            item-text="nombre"
            outlined
            hide-details
            clearable
        />
    </v-col>

    <v-col cols="12" sm="12" md="4">
        <v-menu
            ref="menuDataRange"
            v-model="menuDataRange"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="auto"
        >
            <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    outlined
                    v-model="dateRangeText"
                    label="Desde - Hasta"
                    prepend-inner-icon="mdi-calendar"
                    readonly
                    hide-details
                    v-bind="attrs"
                    v-on="on"
                />
            </template>
            <v-date-picker
                title="es"
                :max="fechaMaximaFiltro"
                v-model="fechas"
                range
                @change="setFiltrosFechas"
                locale="es-Es"
                >
            </v-date-picker>
        </v-menu>
    </v-col>

    <v-col cols="12" sm="12" md="4">
    <v-text-field
        v-model="filters.descripcion"
        label="Descripcion"
        outlined
        clearable
        hide-details
    />
    </v-col>

    <v-col cols="12" sm="12" md="4">
    <v-text-field
        v-model="filters.totales"
        label="Total"
        outlined
        clearable
        hide-details
    />
    </v-col>
    
    <v-col cols="12" sm="12" md="4">
    <div class="d-flex align-center justify-space-around">
        <v-col>
        <v-btn outlined block color="secondary" @click="clearFiltros()"
            >Limpiar</v-btn
        >
        </v-col>
        <v-col>
        <v-btn
            dark
            block
            color="secondary"
            @click="obtainListProcesos"
            >Buscar</v-btn
        >
        </v-col>
    </div>
    </v-col>
    </v-row>
        <DataTableComponent

            :headers="headers"
            :items="requests.data"
            :show_loading="requests.isLoading"
            @paginar="onPaginationChange"
            :total_registros="parseInt(total_rows)"
        >
            <template v-slot:item.created_at="{ item }">
                <span class="d-inline-block" style="min-width: 150px;">
                    {{ formatDate(item.created_at) }}
                </span>
            </template>
            <template v-slot:item.updated_at="{ item }">
                <span class="d-inline-block" style="min-width: 150px;">
                    {{ formatDate(item.updated_at) }}
                </span>
            </template>
            <template v-slot:item.Unidad="{ item }">
                <span class="d-inline-block" style="min-width: 200px;">
                    {{ item.Unidad.nombre }}
                </span>
            </template>
            <template v-slot:item.total="{ item }">
                <span class="d-inline-block" style="min-width: 70px;">
                    ${{ item.total }}
                </span>
            </template>
            <template v-slot:item.actions="{ item }">
                <div class="product-offers-table-actions d-flex justify-center" style="min-width: 100px;"> 
                    <v-btn 
                        text
                        style="min-width: unset !important; min-height: unset !important; height: unset !important;"
                        class="px-2 py-2"
                        @click.stop="$router.push(`/cm-solicitudes-con-observacion/${item.id}`)"
                        :disabled="requests.isLoading"
                    >
                        <v-icon>mdi-archive-eye</v-icon>
                    </v-btn>
                </div>
            </template>
        </DataTableComponent>
    </v-container>
</template>
<script>
import DataTableComponent from "@/components/DataTableComponent.vue";
import { formatDate } from '@/utils/datetime.js';
import { mapActions, mapState } from 'vuex';
import moment from "moment";
import { createLoadable, toggleLoadable, createPageable, togglePageable, setLoadableResponse, setPageableResponse, PAGINATION_PROPERTY_NAMES,} from '@/utils/loadable.js';
const defaultPagination = {page: 1, per_page: 10};

export default {
    components: { DataTableComponent },
    data: () => ({
        headers: [
            { sortable: false, align: 'center', text: 'Tipo', value: 'CMTipoSolicitudCompra.nombre' },
            { sortable: false, align: 'center', text: 'Fecha de creación', value: 'created_at' },
            { sortable: false, align: 'center', text: 'Fecha modificación', value: 'updated_at' },
            { sortable: false, align: 'center', text: 'Unidad', value: 'Unidad' },
            { sortable: false, align: 'center', text: 'Descripción', value: 'descripcion' },
            { sortable: false, align: 'center', text: 'Total', value: 'total' },
            { sortable: false, align: 'center', text: 'Acciones', value: 'actions' },
        ],
        total_rows: 0,
        requests: createPageable([], 10),
        pagination: defaultPagination,
        menuDataRange: false,
        fechas: ["", ""],
        filters: {
            fecha_desde: null,
            fecha_hasta: null,
            descripcion: null,
            totales: null,
            id_ctl_cm_tipo_solicitud: null,
        },
        ctlTipo: [
            {
                id: 1,
                nombre: "Producto",
            },
            {
                id: 2,
                nombre: "Servicio",
            },
        ],
    }),
    computed: {
        fechaMaximaFiltro() {
            // Fecha actual en formato ISO 8601 format
            return moment().format("YYYY-MM-DD");
        },
        dateRangeText() {
            return this.fechas[0] == "" && this.fechas[1] == ""
                ? " "
                : moment(this.fechas[0]).format("D/M/Y") +
                " - " +
                moment(this.fechas[1]).format("D/M/Y");
        },
    },
    methods: {
        formatDate(date) {
            return formatDate(date);
        },
        onPaginationChange(newPagination) {
            const { cantidad_por_pagina, pagina } = newPagination;

            const pagination = {
                [PAGINATION_PROPERTY_NAMES.PAGE_SIZE]: cantidad_por_pagina,
                [PAGINATION_PROPERTY_NAMES.CURRENT_PAGE]: pagina,
            }
            this.pagination = pagination;
            this.obtainListProcesos();
        },
        setFiltrosFechas() {
            this.filters.fecha_desde = this.fechas[0];
            this.filters.fecha_hasta = this.fechas[1];
        },
        async obtainListProcesos() {
            toggleLoadable(this.requests);
            const {data, headers } = await this.services.CMSolicitudesConObservacion.getRequestsWithObservation({...this.filters, ...this.pagination});
            const totalRows = headers['total_rows'];
            setLoadableResponse(this.requests, data );
            this.total_rows = totalRows
        },
        clearFiltros() {
            // Limpiar todo
            this.fechas = ["", ""];
            this.filters.fecha_desde = null;
            this.filters.fecha_hasta = null;
            this.filters.descripcion = null;
            this.filters.totales = null;
            this.filters.id_ctl_cm_tipo_solicitud = null
            this.obtainListProcesos();
        },
    },
    created() {
        this.obtainListProcesos();
    },
}
</script>
<style lang="scss" scoped>
        :deep(
            .theme--light.v-data-table
        ) {
        box-shadow: none !important;
        }
    /* .requests-table tbody tr {
        background-color: #FFFFFF;
    }
    
    .requests-table thead tr th:last-child,
    .requests-table tbody tr td:last-child {
        position: sticky;
        background-color: inherit;
        right: 0;
    }

    .requests-table tbody tr td:last-child {
        background-color: inherit !important;
    } */
</style>