<template>
  <v-row>
    <v-col cols="12" md="12">
      <v-card>
        <v-card-title>
          <h1>Solicitud detalle</h1>
        </v-card-title>
        <v-card-text v-if="solicitud">
          <v-row>
            <v-col md="12" cols="12">
              <v-text-field label="Número de solicitud" v-model="solicitud.id" readonly></v-text-field>
            </v-col>
            <v-col md="12" cols="12">
              <v-text-field label="Solicitante" :value="nombre_completo" readonly></v-text-field>
            </v-col>
            <v-col md="12" cols="12">
              <v-text-field label="Fecha de solicitud" :value="moment(solicitud.created_at).format('DD-MM-YY')"
                            readonly></v-text-field>
            </v-col>
            <v-col md="12" cols="12">
              <v-textarea label="Descripción" v-model="solicitud.descripcion" readonly></v-textarea>
            </v-col>
            <v-col md="12" cols="12">
              <v-textarea label="Dirección" v-model="solicitud.direccion" readonly></v-textarea>
            </v-col>
            <v-col md="12" cols="12">
              <DataTableComponent
                  :items="productosActualizados"
                  :headers="headers"
                  :tiene_paginacion="false"
              >
                <template v-slot:[`item.cumplimiento`]="{ item }">
                  Opciones de cumplimiento
                  <v-chip class="v-badge--bordered primary  white--text"  >Cumplido</v-chip>
                  <v-chip class="v-badge--bordered default text--text" >Pendiente</v-chip>
                </template>
              </DataTableComponent>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn class="primary" @click="cerrar">Cerrar</v-btn>
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
import Fragment from 'vue-fragment';
import DataTableComponent from "../../../components/DataTableComponent.vue";

export default {
  name: "CMProcesoAprobacionCompraElectronicaDetalle",
  data: () => ({
    solicitud: null,
    productosActualizados: [],
    headers: [
      {
        text: 'Producto',
        value: 'CMOfertaProductoConvenio.CMProducto.nombre',
        align: 'center',
        sortable: false,
      },
      {
        text: 'Proveedor',
        align: 'center',
        value: 'CMOfertaProductoConvenio.CMConvenioMarco.Proveedor.nombre',
      },
      {
        text: 'Cantidad',
        value: 'cantidad',
        align: 'center',
        sortable: false,
      },
      {
        text: 'Precio Unitario',
        value: 'precio_de_oferta',
        align: 'center',
        sortable: false,
      },
      {
        text: 'Descripción',
        value: 'CMOfertaProductoConvenio.CMProducto.descripcion',
        align: 'center',
        sortable: false,
      },
      {
        text: 'SubTotal',
        value: 'subtotal',
        align: 'center',
        sortable: false,
      },
      {
        text: 'Cumplimiento',
        value: 'cumplimiento',
        align: 'center',
        sortable: false,
      }
    ],
  }),
  components: {
    DataTableComponent
  },
  watch: {},
  computed: {
    nombre_completo() {
      if (!this.solicitud) return '';
      const {
        primer_nombre = '',
        segundo_nombre = '',
        primer_apellido = '',
        segundo_apellido = '',
        tercer_nombre = '',
        apellido_casada = ''
      } = this.solicitud?.solCom_user?.Empleado?.Persona;
      return `${primer_nombre ?? ''} ${segundo_nombre ?? ''} ${tercer_nombre ?? ''} ${primer_apellido ?? ''} ${segundo_apellido ?? ''} ${apellido_casada ?? ''}`;
    }
  },
  methods: {
    cerrar() {
      this.$emit('close');
    },
    async cargarSolicitud() {
      const {data, status} = await this.services.cmAprobacionSolicitudCompra.detalle(this.id_detalle);
      if (status === 200) {
        const {productosActualizados, solicitud} = data.data;
        this.solicitud = solicitud;
        this.productosActualizados = productosActualizados;
        return true
      }
      this.temporalAlert({
        message: 'Problemas al cargar la solicitud',
        type: 'error'
      })
      return false
    },
  },
  props: {
    id_detalle: {
      type: Number,
      required: true
    },
  },
  async created() {
    const cargada = await this.cargarSolicitud();
  },
}
</script>
