<template>
  <v-container>
    <v-row>
      <v-col md="12" cols="12">
        <v-tabs v-model="tabMenu" background-color="bgMinsal" show-arrows>
          <v-tabs-slider color="secondary"></v-tabs-slider>
          <v-tab tab-value="solicitudesPendientes" key="solicitudesPendientes" class="text-no-style secondary--text">
            Solicitudes pendientes
          </v-tab>
          <v-tab tab-value="solicitudesVistas" key="solicitudesVistas" class="text-no-style secondary--text">
            Solicitudes vistas
          </v-tab>
          <v-tab tab-value="solicitudesConObservacion" key="solicitudesConObservacion" class="text-no-style secondary--text">
            Solicitudes con observaciones
          </v-tab>
          <v-tabs-items class="mt-4 mx-3" :value="tabMenu" style="width: 100%">
            <v-tab-item value="solicitudesPendientes" key="solicitudesPendientes">
              <SolicitudesAprobacion :endpoint="pendingRequestsEndpoint" />
            </v-tab-item>
            <v-tab-item value="solicitudesVistas" key="solicitudesVistas">
              <SolicitudesAprobacion :endpoint="seenRequestsEndpoint" />
            </v-tab-item>
            <v-tab-item value="cumplimientoSolicitudes" key="cumplimientoSolicitudes">
              <listadoCumplimiento />
            </v-tab-item>
            <v-tab-item value="solicitudesConObservacion" key="solicitudesConObservacion">
              <CMSolicitudesConObservacionView />
            </v-tab-item>
          </v-tabs-items>
        </v-tabs>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { SpinnerComponent } from '@/components/utils';
import CMSolicitudesConObservacionView from "@/views/CMSolicitudesConObservacion/CMSolicitudesConObservacionView.vue";
import listadoCumplimiento from "@/views/CMCumplimientoSolicitudes/components/listadoCumplimiento.vue";
import SolicitudesAprobacion from './SolicitudesAprobacion.vue';
const tabs = [
  'solicitudesPendientes',
  'solicitudesConObservacion',
  'solicitudesVistas',
  'cumplimientoSolicitudes',
]
export default {
  name: "CMProcesoAprobacionCompraElectronicaListado",
  data: () => ({
    tabMenu: null,
  }),
  components: {
    SpinnerComponent,
    listadoCumplimiento,
    CMSolicitudesConObservacionView,
    SolicitudesAprobacion,
  },
  computed: {
    pendingRequestsEndpoint() {
      return this.services.cmAprobacionSolicitudCompra.obtenerListado;
    },
    seenRequestsEndpoint() {
      return this.services.cmAprobacionSolicitudCompra.listadoSolicitudesVistas;
    },
  },
  watch:{
    tabMenu(val){
    } 
  },
  mounted() {
    const tabFromQuery = this.$route.query?.active;
    if (tabs.includes(tabFromQuery)) {
      this.tabMenu = tabFromQuery;
    }
  }

}
</script>
