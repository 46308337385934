<template>
    <Fragment>
        <v-row style="padding-top: 1%;">
            <v-col cols="12" sm="12" md="4">
                <p class="text-h6 secondary--text m-0 d-none">
                </p>
                <v-menu ref="RangoFechas" v-model="RangoFechas" :close-on-content-click="false"
                    transition="scale-transition" offset-y min-width="auto">
                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field outlined v-model="dateRangeText" label="Desde - Hasta"
                            prepend-inner-icon="mdi-calendar" readonly hide-details v-bind="attrs" v-on="on" />
                    </template>
                    <v-date-picker title="es" :max="fechaMaxima" v-model="fechas" range @change="setFiltrosFechas"
                        locale="es-Es">
                    </v-date-picker>
                </v-menu>
            </v-col>

            <v-col cols="12" sm="12" md="4">
                <v-text-field v-model="filters.descripcion" label="Descripcion" outlined clearable hide-details />
            </v-col>

            <v-col cols="12" sm="12" md="4">
                <v-text-field v-model="filters.vistaUsuario" label="Usuario" outlined clearable hide-details />
            </v-col>

            <v-col cols="12" sm="12" md="4">
                <div class="d-flex align-center justify-space-around">
                    <v-col>
                        <v-btn outlined block color="secondary" @click="clearFiltros()">Limpiar</v-btn>
                    </v-col>
                    <v-col>
                        <v-btn dark block color="secondary"
                            @click="filtrar">Buscar</v-btn>
                    </v-col>
                </div>
            </v-col>
        </v-row>

        <DataTableComponent 
            :items="requests.data" 
            :headers="headers" 
            :show_actions="false"
            :show_loading="requests.isLoading" 
            :total_registros="total"
            @paginar="(meta) => paginate(meta)"
        >
            <template v-slot:[`item.descripcion`]="{ item }">
                <span class="text-break">{{ item?.descripcion }}</span>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
                <v-btn color="gray" @click="redirectToDetailsPage(item.id)">
                    Detalle
                </v-btn>
            </template>
            <template v-slot:[`item.Unidad`]="{ item }">
            <span class="text-break">{{ item.Unidad.nombre }}</span>
            </template>
            <template v-slot:[`item.usuario`]="{ item }">
                {{ item?.solCom_user?.Empleado?.Persona?.primer_nombre }}
                {{ item?.solCom_user?.Empleado?.Persona?.segundo_nombre }}
                {{ item?.solCom_user?.Empleado?.Persona?.primer_apellido }}
                {{ item?.solCom_user?.Empleado?.Persona?.segundo_apellido }}
            </template>
            <template v-slot:[`item.fechaCreacion`]="{ item }">
                {{ moment(item?.created_at).format('DD-MM-YYYY HH:mm:ss') }}
            </template>
            <template v-slot:[`item.fechaModificacion`]="{ item }">
                {{ moment(item?.updated_at).format('DD-MM-YYYY HH:mm:ss') }}
            </template>
        </DataTableComponent>
    </Fragment>
</template>
<script>
import { createPageable, togglePageable, setPageableResponse,PAGINATION_PROPERTY_NAMES } from '@/utils/loadable';
import DataTableComponent from "@/components/DataTableComponent.vue";
import { Fragment } from 'vue-fragment';
import moment from "moment";

const createPagination = (per_page, page) => ({ per_page, page });
const defaultPagination = createPagination(10, 1);

export default {
    name: 'SolicitudesAprobacion',
    components: {
        Fragment,
        DataTableComponent,
    },
    props: {
        endpoint: { type: Function, required: true },
    },
    data: () => ({
        headers: [
            {
                text: 'Fecha de creación',
                value: 'fechaCreacion',
                align: 'center',
                sortable: false,
            },
            {
                text: 'Fecha modificación',
                value: 'fechaModificacion',
                align: 'center',
                sortable: false,
            },
            {
                text: 'Descripción',
                value: 'descripcion',
                align: 'center',
                sortable: false,
            },
            {
                text: 'Unidad',
                value: 'Unidad',
                align: 'center',
                sortable: false,
            },
            {
                text: 'Usuario',
                value: 'usuario',
                align: 'center',
                sortable: false,
            },
            {
                text: 'Acciones',
                value: 'actions',
                align: 'center',
                sortable: false,
            }
        ],
        requests: createPageable([], 10),
        RangoFechas: false,
        fechas: ["", ""],
        filters: {
            fecha_desde: null,
            fecha_hasta: null,
            descripcion: null,
            vistaUsuario: null,
        },
    }),
    computed: {
        fechaMaxima() {
            return moment().format("YYYY-MM-DD");
        },
        dateRangeText() {
            return this.fechas[0] == "" && this.fechas[1] == ""
                ? " "
                : moment(this.fechas[0]).format("D/M/Y") +
                " - " +
                moment(this.fechas[1]).format("D/M/Y");
        },
        total() {
            return this.requests.pagination[PAGINATION_PROPERTY_NAMES.COUNT];
        },
    },
    methods: {
        redirectToDetailsPage(id) {
            this.$router.push({ name: 'cm-aprobacion-solicitud-compra-detalle', params: { id } })
        },
        async loadRequests(pagination = defaultPagination, filters) {
            togglePageable(this.requests);
            const mergedFilters = { ...pagination, ...filters };
            const { data, headers } = await this.endpoint(mergedFilters);
            setPageableResponse(this.requests, data, headers);
        },
        paginate({ cantidad_por_pagina, pagina }) {
            const pagination = createPagination(cantidad_por_pagina, pagina);
            this.loadRequests(pagination);
        },
        setFiltrosFechas() {
            this.filters.fecha_desde = this.fechas[0];
            this.filters.fecha_hasta = this.fechas[1];
        },
        clearFiltros() {
            this.fechas = ["",""];
            this.filters.fecha_desde = null;
            this.filters.fecha_hasta = null;
            this.filters.descripcion = null;
            this.filters.vistaUsuario = null;
            this.loadRequests(defaultPagination);
        },
        filtrar() {
            this.loadRequests(defaultPagination, this.filters);
        },
    },
    created() {
        this.loadRequests();
    },
}

</script>